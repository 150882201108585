import classNames from 'classnames'
import { TFunction } from 'i18next'
import { useTranslation } from 'next-i18next'
import { OrderExpectWaitStatusEnum, SiteDispatchStatusRes, SiteRes, SubscriptionTypeEnum } from '@/api/generated/types'
import Icon from '@/components/common/Icon'
import StaticImg from '@/components/common/StaticImg'
import { I18nNamespaceEnum } from '@/constants/i18n'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'
import useLocale from '@/hooks/common/useLocale'
import useSubscribePushNotification from '@/hooks/common/useSubscribePushNotification'
import { isSite } from '@/pages/_app'
import { DateUtils } from '@/utils/date'

type RobotsStatusInfo = {
  robotStatus?: SiteDispatchStatusRes
  site?: SiteRes
}

const getWaitRobotInfoDetailText = (hasSubscribed: boolean, t: TFunction) => {
  if (hasSubscribed) {
    return t('home:card.robot.push_notification_subscribed')
  }
  return t('home:card.robot.push_notification_subscription_available')
}

const getDisabledRobotInfoDetailText = (robotStatusInfo: RobotsStatusInfo, t: TFunction) => {
  const { robotStatus, site } = robotStatusInfo
  const isSiteClose = !DateUtils.getNowIsAble(site?.openAt, site?.lastOrderAt)
  const isSiteBreakTime = DateUtils.getNowIsAble(site?.breakStartAt, site?.breakEndAt)

  if (isSiteClose) {
    return t('home:card.common.not_business_hour')
  }
  if (isSiteBreakTime) {
    return isSite(SiteSlugEnum.Secsuwon) ? t('home:card.common.maintenance') : t('home:card.common.not_business_hour')
  }
  if (
    robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.UNAVAILABLE &&
    robotStatus.orderExpectWaitSec === null
  ) {
    return t('home:card.common.maintenance')
  }
  return t('home:card.robot.too_many_orders')
}

const CardText = ({ t, robotStatus }: { t: TFunction; robotStatus?: SiteDispatchStatusRes }) => {
  if (robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.AVAILABLE) {
    return (
      <>
        <span className="title3 font-bold text-white">
          {t('home:card.robot.possible_orders_count1', { count: robotStatus?.orderCanDispatchCount })}
        </span>
        <span className="title3 font-bold text-white">
          {t('home:card.robot.possible_orders_count2', { count: robotStatus?.orderCanDispatchCount })}
        </span>
      </>
    )
  }

  if (robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.WAIT) {
    return (
      <>
        <span className="title4 font-bold text-white">{t('home:card.robot.wait_order1')}</span>
        <span className="title4 font-bold text-white">{t('home:card.robot.wait_order2')}</span>
      </>
    )
  }

  return (
    <>
      <span className="title3 font-bold text-white">{t('home:card.robot.unavailable_order1')}</span>
      <span className="title3 font-bold text-white">{t('home:card.robot.unavailable_order2')}</span>
    </>
  )
}

const isWaitRobot = (robotStatus: SiteDispatchStatusRes | undefined) => {
  return robotStatus && robotStatus.orderExpectWaitStatus === OrderExpectWaitStatusEnum.WAIT
}

const RobotsStatusInfo = ({ robotStatus, site }: RobotsStatusInfo) => {
  const { t } = useTranslation([I18nNamespaceEnum.Home])
  const { formatMinSec } = useLocale()
  const isAvailable = robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.AVAILABLE
  const { hasSubscribed, createPushSubscriptions } = useSubscribePushNotification()

  const handleSubscribeClick = () => {
    if (!hasSubscribed) {
      createPushSubscriptions({
        data: {
          subscriptionType: SubscriptionTypeEnum.SITE_NEUBIE_AVAILABLE
        }
      })
    }
  }

  return (
    <section className="relative w-full">
      <div className="h-130 w-full">
        <div className="relative flex h-85 w-full items-center rounded-t-sm bg-primary-300 px-12">
          <div className="flex flex-col">
            <CardText t={t} robotStatus={robotStatus} />
          </div>
          <div className="absolute right-95 h-85">
            <span className="text-72 font-bold leading-86 text-white">{robotStatus?.orderCanDispatchCount}</span>
          </div>
          <div className="robot-info-gradient absolute right-90 top-10 h-65 w-30 " />
          <StaticImg
            className="bottom-8 right-21 h-89 w-68"
            src="/images/home-robot.png"
            alt="Robot info neubie image"
            position="absolute"
          />
        </div>
        {isAvailable ? (
          <div
            className={classNames(
              'flex h-45 w-full items-center justify-between rounded-b-sm bg-primary-700 px-12 text-info'
            )}>
            <div className="flex items-center gap-4">
              <Icon name={IconNamesEnum.Information} className="h-16 w-16" />
              <span className="body3">{t('home:card.robot.time_remaining')}</span>
            </div>
            {!!robotStatus?.orderExpectArrivedSec && (
              <span className="body3 font-bold">{formatMinSec(robotStatus?.orderExpectArrivedSec)}</span>
            )}
          </div>
        ) : (
          <div
            className={classNames(
              'flex h-45 w-full items-center justify-between rounded-b-sm bg-primary-200 px-12 text-white'
            )}>
            <div className="flex items-center gap-4">
              <Icon name={IconNamesEnum.Information} className="h-16 w-16" />
              <span className={classNames('body3')}>
                {isWaitRobot(robotStatus)
                  ? getWaitRobotInfoDetailText(hasSubscribed, t)
                  : getDisabledRobotInfoDetailText({ site, robotStatus }, t)}
              </span>
            </div>
            {!hasSubscribed && isWaitRobot(robotStatus) && (
              <Icon
                onClick={handleSubscribeClick}
                name={IconNamesEnum.ChevronRight}
                className="h-20 w-20 cursor-pointer"
              />
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default RobotsStatusInfo
