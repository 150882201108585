import { useEffect, useRef, useState } from 'react'
import { useSitesBottomSheetsList } from '@/api/generated/hooks'
import { SiteBottomSheetRes } from '@/api/generated/types'
import { EventHideInfoType } from '@/components/shop/EventInfoBottomSheet'
import useBottomSheet from '@/hooks/common/useBottomSheet'
import useLocale from '@/hooks/common/useLocale'
import { DateUtils } from '@/utils/date'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'

export type SiteBottomSheetHideTodayInfo = {
  [index: number]: string
}

interface UseSiteBottomSheetProps {
  siteSlug: string | undefined
}

export type UseSiteBottomSheetReturnType = ReturnType<typeof useSiteBottomSheet>

const useSiteBottomSheet = ({ siteSlug }: UseSiteBottomSheetProps) => {
  const { locale } = useLocale()
  const bottomSheetControls = useBottomSheet<SiteBottomSheetRes>()
  const siteBottomSheetCurrentIndexRef = useRef<number>()
  const { data: _siteBottomSheetList, isLoading: isSiteBottomSheetListLoading } = useSitesBottomSheetsList(
    siteSlug || '',
    {},
    { query: { enabled: !!siteSlug } }
  )
  const [siteBottomSheetList, setSiteBottomSheetList] = useState<SiteBottomSheetRes[]>()

  useEffect(() => {
    if (_siteBottomSheetList) {
      const siteBottomSheetListNew = _siteBottomSheetList.filter((siteBottomSheet) => {
        const siteBottomSheetHideTodayInfo = LocalStorage.getItem<SiteBottomSheetHideTodayInfo>(
          LocalStorageKeyEnum.SiteBottomSheetHideToday
        )
        if (!siteBottomSheetHideTodayInfo) return true
        return (
          siteBottomSheetHideTodayInfo[siteBottomSheet.id] !==
          DateUtils.formatDate(new Date().toUTCString(), 'YYYY-MM-DD', locale)
        )
      })
      setSiteBottomSheetList(siteBottomSheetListNew)
    }
  }, [_siteBottomSheetList])

  const updateBottomSheetHideToday = (siteBottomSheetId: number) => {
    const siteBottomSheetHideTodayInfo =
      LocalStorage.getItem<SiteBottomSheetHideTodayInfo>(LocalStorageKeyEnum.SiteBottomSheetHideToday) ?? {}
    siteBottomSheetHideTodayInfo[siteBottomSheetId] = DateUtils.formatDate(
      new Date().toUTCString(),
      'YYYY-MM-DD',
      locale
    )
    LocalStorage.setItem<EventHideInfoType>(LocalStorageKeyEnum.SiteBottomSheetHideToday, siteBottomSheetHideTodayInfo)
  }

  const nextSiteBottomSheetIndex =
    siteBottomSheetCurrentIndexRef.current !== undefined ? siteBottomSheetCurrentIndexRef.current + 1 : 0
  const nextSiteBottomSheet = siteBottomSheetList?.[nextSiteBottomSheetIndex]
  const hasNextSiteBottomSheet = !!nextSiteBottomSheet

  const openNextSiteBottomSheet = () => {
    if (!hasNextSiteBottomSheet) {
      return false
    }
    siteBottomSheetCurrentIndexRef.current = nextSiteBottomSheetIndex
    bottomSheetControls.setBottomSheetData(nextSiteBottomSheet)
    bottomSheetControls.handleOpen()
    return true
  }

  const closeBottomSheet = () => {
    bottomSheetControls.handleClose()
  }

  return {
    siteBottomSheetList,
    bottomSheetControls,
    nextSiteBottomSheet,
    hasNextSiteBottomSheet: !!nextSiteBottomSheet,
    openNextSiteBottomSheet,
    closeBottomSheet,
    updateBottomSheetHideToday
  }
}

export default useSiteBottomSheet
