import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Icon from '@/components/common/Icon'
import { I18nNamespaceEnum } from '@/constants/i18n'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { Routes } from '@/constants/routes'

/**
 * 디자인 시스템을 따르는 바텀 네비게이션 바 입니다.
 */
const NavigationBar = () => {
  const { t } = useTranslation([I18nNamespaceEnum.Common])
  const { pathname } = useRouter()

  return (
    <>
      <nav className="fixed bottom-0 z-header flex h-[calc(var(--safe-area-inset-bottom)+5.7rem)] w-full max-w-body items-start border-t-1 border-t-gray-100 bg-white py-8">
        <Link
          href={Routes.Home}
          className={classNames('flex w-full flex-col items-center justify-center', {
            'text-primary': pathname === Routes.Home
          })}>
          <Icon name={pathname === Routes.Home ? IconNamesEnum.HomeFilledTrue : IconNamesEnum.HomeFilledFalse} />
          <span className="caption font-medium">{t('common:navi_bottom.1')}</span>
        </Link>
        <Link
          href={Routes.OrderHistory}
          className={classNames('flex w-full flex-col items-center justify-center', {
            'text-primary': pathname === Routes.OrderHistory
          })}>
          <Icon
            name={
              pathname === Routes.OrderHistory ? IconNamesEnum.OrderListFilledTrue : IconNamesEnum.OrderListFilledFalse
            }
          />
          <span className="caption font-medium">{t('common:navi_bottom.2')}</span>
        </Link>
        <Link
          href={Routes.MyPage.Index}
          className={classNames('flex w-full flex-col items-center justify-center', {
            'text-primary': pathname === Routes.MyPage.Index
          })}>
          <Icon
            name={pathname === Routes.MyPage.Index ? IconNamesEnum.SmileFilledTrue : IconNamesEnum.SmileFilledFalse}
          />
          <span className="caption font-medium">{t('common:navi_bottom.3')}</span>
        </Link>
      </nav>
      <div className="h-[calc(var(--safe-area-inset-bottom)+5.7rem)] w-full max-w-body" />
    </>
  )
}

export default NavigationBar
