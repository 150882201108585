import { useEffect } from 'react'
import { GetStaticProps, GetStaticPropsContext } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { ShopRes } from '@/api/generated/types'
import LoadingFallback from '@/components/LoadingFallback'
import Icon from '@/components/common/Icon'
import MutateLoadingSpinner from '@/components/common/MutateLoadingSpinner'
import Badge from '@/components/common/design-system/Badge'
import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import NavigationBar from '@/components/common/design-system/NavigationBar'
import Toast from '@/components/common/design-system/Toast'
import Header, { SkeletonHeaderCenter } from '@/components/common/header/Header'
import InfiniteContainer from '@/components/common/infinite/InfiniteContainer'
import SkeletonBase from '@/components/common/skeleton/SkeletonBase'
import HomeFooter from '@/components/home/HomeFooter'
import HomeShopCard, { SkeletonHomeCardList } from '@/components/home/HomeShopCard'
import RobotStatusBottomSheet from '@/components/home/RobotStatusBottomSheet'
import RobotsStatusInfo from '@/components/home/RobotsStatusInfo'
import SiteBottomSheet from '@/components/home/SiteBottomSheet'
import SiteMapBottomSheet from '@/components/home/SiteMapBottomSheet'
import MainBody from '@/components/layouts/MainBody'
import PolicyBottomSheet from '@/components/policy/PolicyBottomSheet'
import { DeployEnvironmentEnum } from '@/constants/deployEnvironment.enum'
import { I18nNamespaceEnum, LocaleEnum } from '@/constants/i18n'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { QueryKeys, Routes } from '@/constants/routes'
import GoogleMapContainer from '@/containers/GoogleMapContainer'
import ScrollRestoreContainer from '@/containers/ScrollRestoreContainer'
import HomeContainer from '@/containers/pages/home/HomeContainer'
import useLocale from '@/hooks/common/useLocale'
import { PullToRefreshStatusEnum } from '@/hooks/common/usePullToRefresh'
import useSiteBottomSheet from '@/hooks/home/useSiteBottomSheet'
import { LocalStorage } from '@/utils/localStorage'

const pageHomeI18nNamespaces = [
  I18nNamespaceEnum.Home,
  I18nNamespaceEnum.CompanyInfo,
  I18nNamespaceEnum.Common,
  I18nNamespaceEnum.Cabin,
  I18nNamespaceEnum.Withdrawal
]

const PageHomeContent = () => {
  const { t } = useTranslation(pageHomeI18nNamespaces)
  const {
    policyBottomSheetControls,
    robotStatusBottomSheetControls,
    siteNodeDropDownControls,
    siteMapBottomSheetControls,
    toastControls,
    promiseNodeName,
    isNodeListFetching,
    handleSavePromiseNode,
    handleRequestCurrentLocationClick,
    handleSiteMapOpen,
    handleOpenPolicy,
    shopList,
    fetchNextShopList,
    site,
    cartCount,
    robotStatus,
    pullToRefreshStatus,
    // neomPromotionInfo,
    isCreatePushSubscriptionsLoading,
    siteSlug
  } = HomeContainer.useContainer()

  const { query, replace, reload } = useRouter()

  useEffect(() => {
    if (query?.[QueryKeys.WithdrawalSuccess]) {
      toastControls.addToast(t('withdrawal:toast.withdrawal_completed'))
      replace(Routes.Home)
      return
    }

    if (query?.[QueryKeys.OrderNotificationSubscriptionSuccess]) {
      toastControls.addToast(t('common:toast.completed_push_notification_subscribe'))
      replace(Routes.Home)
      return
    }
  }, [query])

  // TODO: neom측 요청으로 프로모션 관련 코드는 숨김
  // useEffect(() => {
  //   if (SessionStorage.getItem<boolean>(SessionStorageKeyEnum.SuccessReceivePoint)) {
  //     SessionStorage.removeItem(SessionStorageKeyEnum.SuccessReceivePoint)
  //     toastControls.addToast(
  //       t('cabin:toast.success_receive_point', { point: String(neomPromotionInfo?.defaultPoint ?? 0) + WalletUnit })
  //     )
  //     return
  //   }
  // }, [])

  const siteBottomSheetControls = useSiteBottomSheet({ siteSlug })

  return (
    <>
      <MainBody>
        <Header
          center={
            <button className="flex w-full justify-center" onClick={handleSiteMapOpen}>
              <Icon name={IconNamesEnum.LocationFilledFalse} className="h-24 w-24" />
              <LoadingFallback isLoading={isNodeListFetching} fallback={<SkeletonHeaderCenter />}>
                <h1 className="body1 truncate font-bold">{promiseNodeName ?? t('home:header.title')}</h1>
              </LoadingFallback>
            </button>
          }
          rightSide={
            <Link href={Routes.Cart} className="relative flex h-full w-full items-center justify-end">
              <Icon name={IconNamesEnum.Basket} className="h-24 w-24" />
              {!!cartCount && (
                <Badge
                  content={String(cartCount)}
                  className="absolute top-[-1rem] right-[-1rem] bg-primary text-white"
                />
              )}
            </Link>
          }
          pullToRefreshStatus={pullToRefreshStatus}
        />
        <div className="px-16">
          {/*<LoadingFallback*/}
          {/*  isLoading={*/}
          {/*    isUserWalletFetching ||*/}
          {/*    isUserProfileFetching ||*/}
          {/*    isNeomPromotionInfoFetching ||*/}
          {/*    pullToRefreshStatus === PullToRefreshStatusEnum.Refreshing*/}
          {/*  }*/}
          {/*  fallback={<SkeletonPointStatusBanner />}>*/}
          {/*<PointStatusBanner*/}
          {/*  handlePointPromotionBannerClick={handlePointPromotionBannerClick}*/}
          {/*  balance={userProfile?.isReceivedNeomPoint ? userWallet?.balance : undefined}*/}
          {/*  neomPoint={neomPromotionInfo?.defaultPoint}*/}
          {/*/>*/}
          {/*</LoadingFallback>*/}
          <div className="h-36 w-full" />
          <LoadingFallback
            isLoading={!robotStatus || pullToRefreshStatus === PullToRefreshStatusEnum.Refreshing}
            fallback={<SkeletonBase className="h-130 w-full rounded-sm" />}>
            <RobotsStatusInfo robotStatus={robotStatus} site={site} />
          </LoadingFallback>
          <div className="h-24 w-full" />
          <div className="body2 flex gap-2 text-gray-500">
            <LoadingFallback
              isLoading={!site?.name || pullToRefreshStatus === PullToRefreshStatusEnum.Refreshing}
              fallback={<SkeletonBase className="h-22 w-160 rounded-xss" />}>
              <span>{site?.name}</span>
              <span className="font-bold">{shopList?.pages[0].count}</span>
            </LoadingFallback>
          </div>
          <div className="h-12 w-full" />
          <LoadingFallback
            isLoading={!shopList || !site || pullToRefreshStatus === PullToRefreshStatusEnum.Refreshing}
            fallback={<SkeletonHomeCardList />}>
            <InfiniteContainer<ShopRes>
              className="flex flex-col gap-22"
              threshold={0.5}
              scrollData={shopList}
              fetchNextPage={fetchNextShopList}>
              {({ itemRes }) => {
                return <HomeShopCard shop={itemRes} site={site} />
              }}
            </InfiniteContainer>
          </LoadingFallback>
          <div className="h-40 w-full" />
        </div>
        <HomeFooter handleOpenPolicy={handleOpenPolicy} />
        {process.env.NEWBIE_ORDER_ENV !== DeployEnvironmentEnum.Production && (
          <div className="flex flex-row gap-10">
            <Link href={Routes.Sites}>
              <Button theme={ButtonThemeEnum.Primary} size={ButtonSizeEnum.Medium}>
                {t('common:select_site')}
              </Button>
            </Link>
            <ChangeLocale />
            <Button
              onClick={() => {
                LocalStorage.clearAll()
                reload()
              }}>
              LocalStorage 초기화
            </Button>
          </div>
        )}
        <NavigationBar />
      </MainBody>
      <PolicyBottomSheet bottomSheetControls={policyBottomSheetControls} />
      <RobotStatusBottomSheet bottomSheetControls={robotStatusBottomSheetControls} />
      <BottomSheetLegacy bottomSheetControls={siteMapBottomSheetControls} height="h-[80vh]">
        <SiteMapBottomSheet
          bottomSheetControls={siteMapBottomSheetControls}
          dropDownControls={siteNodeDropDownControls}
          handleSavePromiseNode={handleSavePromiseNode}
          handleRequestCurrentLocationClick={handleRequestCurrentLocationClick}
        />
      </BottomSheetLegacy>
      <Toast toastControls={toastControls} />
      <SiteBottomSheet {...siteBottomSheetControls} />
      <MutateLoadingSpinner isLoading={isCreatePushSubscriptionsLoading} />
    </>
  )
}

const PageHome = () => {
  return (
    <ScrollRestoreContainer.Provider>
      <GoogleMapContainer.Provider>
        <HomeContainer.Provider>
          <PageHomeContent />
        </HomeContainer.Provider>
      </GoogleMapContainer.Provider>
    </ScrollRestoreContainer.Provider>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }: GetStaticPropsContext) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, pageHomeI18nNamespaces))
    }
  }
}

const ChangeLocale = () => {
  const { locale, setLocale } = useLocale()

  if (locale === LocaleEnum.Korean) {
    return <Button onClick={() => setLocale(LocaleEnum.Arabic_SaudiArabia)}>English</Button>
  }

  if (locale === LocaleEnum.Arabic_SaudiArabia) {
    return <Button onClick={() => setLocale(LocaleEnum.Korean)}>한국어</Button>
  }

  return null
}

export default PageHome
