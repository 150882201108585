import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { extend as dayjsExtend } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { createContainer } from 'unstated-next'
import {
  getCartsCountRetrieveQueryKey,
  getNodesListQueryKey,
  getShopsListQueryKey,
  getSitesDispatchStatusRetrieveQueryKey,
  getSitesSlugRetrieveQueryKey,
  getUsersProfileRetrieveQueryKey,
  getUsersWalletRetrieveQueryKey,
  shopsList,
  useCartsCountRetrieve,
  useSitesDispatchStatusRetrieve,
  useSitesSlugRetrieve
} from '@/api/generated/hooks'
import { ShopRes, ShopsListOrderByItem, ShopsListParams } from '@/api/generated/types'
import { RobotStatusBottomSheetDataType } from '@/components/home/RobotStatusBottomSheet'
import { PolicyBottomSheetDataType, PolicyTypeEnum } from '@/components/policy/PolicyBottomSheet'
import { I18nNamespaceEnum } from '@/constants/i18n'
import { Routes } from '@/constants/routes'
import AuthContainer, { LoginStatusEnum } from '@/containers/app/AuthContainer'
import useBottomSheetLegacy from '@/hooks/common/useBottomSheetLegacy'
import useInfinityScroll from '@/hooks/common/useInfinityScroll'
import usePullToRefresh from '@/hooks/common/usePullToRefresh'
import useSubscribePushNotification from '@/hooks/common/useSubscribePushNotification'
import useToast from '@/hooks/common/useToast'
import usePromiseNode from '@/hooks/usePromiseNode'
import { isInApp, isServer } from '@/pages/_app'
import { DateUtils } from '@/utils/date'
import { FetchUtils } from '@/utils/fetch'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import { SessionStorage, SessionStorageKeyEnum } from '@/utils/sessionStorage'

dayjsExtend(utc)
dayjsExtend(timezone)

const useHome = () => {
  const { t } = useTranslation([I18nNamespaceEnum.Home, I18nNamespaceEnum.Common])
  const policyBottomSheetControls = useBottomSheetLegacy<PolicyBottomSheetDataType>()
  const robotStatusBottomSheetControls = useBottomSheetLegacy<RobotStatusBottomSheetDataType>()
  const toastControls = useToast()
  const { replace, push } = useRouter()
  const { loginStatus } = AuthContainer.useContainer()
  const siteSlug = isServer ? 'null' : LocalStorage.getItem<string>(LocalStorageKeyEnum.SiteSlug) ?? 'null'
  const { data: site } = useSitesSlugRetrieve(siteSlug, {
    query: {
      enabled: !isServer,
      cacheTime: 60 * 60 * 1000
    }
  })
  const { hasSubscribed, createPushSubscriptions, isCreatePushSubscriptionsLoading } = useSubscribePushNotification()

  const {
    siteMapBottomSheetControls,
    siteNodeDropDownControls,
    handleSiteMapOpen,
    nodeList,
    handleRequestCurrentLocationClick,
    promiseNodeName,
    setPromiseNode,
    isNodeListFetching
  } = usePromiseNode({ site })

  const queryClient = useQueryClient()

  const { data: shopList, fetchNextPage: fetchNextShopList } = useInfinityScroll<ShopRes, ShopsListParams>(
    {
      queryKey: getShopsListQueryKey({ orderBy: [ShopsListOrderByItem.is_available] }),
      queryFn: shopsList,
      itemPerPage: 5
    },
    {
      query: {
        cacheTime: 60 * 60 * 1000
      }
    }
  )

  const { data: cartCount } = useCartsCountRetrieve({
    query: {
      cacheTime: 0,
      enabled: loginStatus === LoginStatusEnum.Login
    }
  })

  const { data: robotStatus } = useSitesDispatchStatusRetrieve({
    query: {
      cacheTime: 0
    }
  })

  // const { data: neomPromotionInfo, isFetching: isNeomPromotionInfoFetching } = useNeomPromotionsInfoRetrieve({
  //   query: {
  //     cacheTime: 60 * 60,
  //     staleTime: 60 * 60,
  //   }
  // })

  /**
   * 가용 로봇 상태 바텀 시트
   */
  useEffect(() => {
    if (!robotStatus || !site) return

    // 로봇 가용 대수가 있다면 뜨지 않습니다.
    // 가용로봇 바텀시트를 첫 한번만 노출 합니다.
    const isFirstRenderRobotStatusSheet = SessionStorage.getItem<boolean>(
      SessionStorageKeyEnum.IsFirstRenderRobotStatusSheet
    )
    // 주문 가능 카운트가 있으면 가용로봇 바텀시트 노출상태를 초기화 합니다.
    if (robotStatus.orderCanDispatchCount) {
      SessionStorage.removeItem(SessionStorageKeyEnum.IsFirstRenderRobotStatusSheet)
      return
    }

    const isSiteBreakTime = DateUtils.getNowIsAble(site?.breakStartAt, site?.breakEndAt)
    const isSiteOpen = DateUtils.getNowIsAbleWithEmpty(site?.openAt, site?.lastOrderAt)
    // 사이트 브레이크 타임이거나, 사이트 오픈 시간이 아니라면 로봇 상태 바텀시트를 띄우지 않습니다.
    if (!isSiteOpen || isSiteBreakTime) return

    // 이미 가용로봇 바텀시트를 띄운적이 있다면 띄우지 않습니다.
    if (isFirstRenderRobotStatusSheet === false) return

    // 가용로봇 상태를 띄우고 띄운적이 있다는 상태로 변경합니다.
    robotStatusBottomSheetControls.setBottomSheetData({ robotStatus })
    robotStatusBottomSheetControls.handleOpen()
    SessionStorage.setItem(SessionStorageKeyEnum.IsFirstRenderRobotStatusSheet, false)
  }, [robotStatus, site])

  const handleOpenPolicy = (type: PolicyTypeEnum) => {
    policyBottomSheetControls.setBottomSheetData({ selectedPolicyType: type })
    policyBottomSheetControls.handleOpen()
  }

  const handleSavePromiseNode = () => {
    if (!siteNodeDropDownControls.selectedItem?.id) {
      return
    }
    setPromiseNode({
      nodeId: siteNodeDropDownControls.selectedItem.id,
      onClose: () => {
        toastControls.addToast(t('common:toast.completed_meeting_point'))
        siteMapBottomSheetControls.handleClose()
      }
    })
  }

  const handlePointPromotionBannerClick = () => {
    SessionStorage.setItem(SessionStorageKeyEnum.IsEntryPointBanner, true)
    push(Routes.Cabin)
  }

  const { pullToRefreshStatus } = usePullToRefresh(async () => {
    await FetchUtils.refreshByQueryKey({
      queryClient,
      refetchQueryKeys: [
        getSitesSlugRetrieveQueryKey(siteSlug),
        getCartsCountRetrieveQueryKey(),
        getSitesDispatchStatusRetrieveQueryKey(),
        getNodesListQueryKey(),
        getShopsListQueryKey({ orderBy: [ShopsListOrderByItem.is_available] }),
        getUsersProfileRetrieveQueryKey(),
        getUsersWalletRetrieveQueryKey()
      ]
    })
  })

  useEffect(() => {
    if (isInApp() && !LocalStorage.getItem(LocalStorageKeyEnum.IsInformPermissions)) {
      replace(Routes.InformPermissions)
    }
  }, [])

  return {
    policyBottomSheetControls,
    robotStatusBottomSheetControls,
    siteMapBottomSheetControls,
    siteNodeDropDownControls,
    toastControls,
    handleSavePromiseNode,
    handleRequestCurrentLocationClick,
    handleSiteMapOpen,
    handleOpenPolicy,
    handlePointPromotionBannerClick,
    cartCount: cartCount?.count,
    nodeList,
    isNodeListFetching,
    shopList,
    fetchNextShopList,
    site,
    robotStatus,
    pullToRefreshStatus,
    promiseNodeName,
    // neomPromotionInfo,
    // isNeomPromotionInfoFetching,
    hasSubscribed,
    createPushSubscriptions,
    isCreatePushSubscriptionsLoading,
    siteSlug
  }
}

const HomeContainer = createContainer(useHome)

export default HomeContainer
