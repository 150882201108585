import { useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Icon from '@/components/common/Icon'
import StaticImg from '@/components/common/StaticImg'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import CTAButton from '@/components/common/design-system/CTAButton'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { UseSiteBottomSheetReturnType } from '@/hooks/home/useSiteBottomSheet'
import { ComponentUtils } from '@/utils/componentUtils'
import BottomSheet from '../common/design-system/BottomSheet'

const getJustifyContent = (isShowHideForTodayButton: boolean, isShowCloseButton: boolean) => {
  if (isShowHideForTodayButton && isShowCloseButton) {
    return 'justify-between'
  }
  if (isShowHideForTodayButton) {
    return 'justify-start'
  }
  return 'justify-end'
}

type SiteBottomSheetProps = UseSiteBottomSheetReturnType

const SiteBottomSheet = ({
  bottomSheetControls,
  openNextSiteBottomSheet,
  closeBottomSheet,
  updateBottomSheetHideToday,
  nextSiteBottomSheet
}: SiteBottomSheetProps) => {
  const { t } = useTranslation()
  const { bottomSheetData, mount } = bottomSheetControls
  const {
    id,
    title,
    content,
    bottomSheetImage: { image = '/images/tilt-robot.png', alt } = {},
    isShowHideForTodayButton,
    isShowCloseButton,
    buttonText
  } = bottomSheetData

  const handleHideTodayButtonClick = () => {
    updateBottomSheetHideToday(id)
    closeBottomSheet()
  }

  const handleCloseButtonClick = () => {
    closeBottomSheet()
  }

  const handleDimClick = () => {
    if (
      !bottomSheetData?.isClosable ||
      !bottomSheetData?.buttonText ||
      !!(bottomSheetData?.buttonText && bottomSheetData.isShowCloseButton)
    ) {
      return
    }
    closeBottomSheet()
  }

  useEffect(() => {
    if (!mount && nextSiteBottomSheet) {
      openNextSiteBottomSheet()
    }
  }, [mount, nextSiteBottomSheet])

  return (
    <BottomSheet
      bottomSheetControls={bottomSheetControls}
      background="bg-gray-50"
      topSide={
        (isShowHideForTodayButton || isShowCloseButton) && (
          <div
            className={classNames(
              'mb-16 flex items-center px-bodySide',
              getJustifyContent(!!isShowHideForTodayButton, !!isShowCloseButton)
            )}>
            {isShowHideForTodayButton && (
              <button className="flex items-center gap-4" onClick={handleHideTodayButtonClick}>
                <Icon name={IconNamesEnum.Close} className="h-24 w-24 text-white" />
                <span className="body2 font-medium text-white">{t('common:site-bottom-sheet.do_not_show_again')}</span>
              </button>
            )}
            {isShowCloseButton && (
              <button className="body2 font-medium text-white" onClick={handleCloseButtonClick}>
                {t('common:close')}
              </button>
            )}
          </div>
        )
      }
      handleDimClick={handleDimClick}>
      <section className="max-h-[calc(100vh-14rem)] overflow-y-auto px-48 pt-40 pb-24">
        <div className="flex w-full flex-col items-center justify-center">
          <span className={ComponentUtils.cn('title4 break-all text-center font-bold', !content && 'mb-4')}>
            {title}
          </span>
          {content && <span className="body3 mt-8 break-all text-center font-medium text-gray-700">{content}</span>}
          <StaticImg className="mt-24 h-150 w-150" src={image} alt={alt || ''} />
        </div>
      </section>
      {buttonText && (
        <CTAButton divider={false} background="bg-gray-50">
          <Button
            full
            theme={ButtonThemeEnum.Primary}
            size={ButtonSizeEnum.ExtraLarge}
            onClick={handleCloseButtonClick}>
            <span className="body1 truncate font-bold">{buttonText}</span>
          </Button>
        </CTAButton>
      )}
    </BottomSheet>
  )
}

export default SiteBottomSheet
