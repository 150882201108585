import { forwardRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { motion, useAnimation } from 'framer-motion'

export const ImgAssetEnum = {
  Placeholder: 'placeholder'
}

export const ImgAsset = {
  placeholder: {
    src: '/images/skeleton-img.png',
    alt: '기본 이미지'
  }
} as const

type ImgProps = {
  className: string
  src?: string | null
  alt: string
  position?: 'absolute' | 'relative' | 'fixed'
  overlay?: boolean
  placeholder?: string
}

const Img = forwardRef<HTMLDivElement, ImgProps>(
  ({ className, src, alt, position = 'relative', overlay = false, placeholder = ImgAsset.placeholder.src }, ref) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const controls = useAnimation()

    useEffect(() => {
      if (isLoaded) {
        controls.start('visible')
      }
    }, [isLoaded])

    return (
      <div className={classNames(position, className, 'overflow-hidden')} ref={ref}>
        {!isLoaded && <img className="h-full w-full object-cover" src={placeholder} alt={alt} />}
        <motion.img
          initial="hidden"
          animate={controls}
          transition={{ duration: 0.3 }}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 }
          }}
          className={classNames('h-full w-full object-cover', !isLoaded && 'hidden')}
          src={src ?? placeholder}
          alt={alt}
          onLoad={() => {
            setIsLoaded(true)
          }}
        />
        {overlay && <div className={classNames(className, 'absolute top-0 bg-gray-900/[0.05]')} />}
      </div>
    )
  }
)

Img.displayName = 'Img'

export default Img
