import { useEffect } from 'react'
import classNames from 'classnames'
import { TFunction } from 'i18next'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { ShopRes, SiteRes } from '@/api/generated/types'
import Icon from '@/components/common/Icon'
import Img from '@/components/common/Img'
import Tag from '@/components/common/design-system/Tag'
import SkeletonBase from '@/components/common/skeleton/SkeletonBase'
import { I18nNamespaceEnum } from '@/constants/i18n'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { OpenStatusEnum } from '@/constants/openStatus.enum'
import { RouteKeys, Routes } from '@/constants/routes'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'
import { isSite } from '@/pages/_app'
import { DateUtils } from '@/utils/date'
import { ShopUtils } from '@/utils/shop'

type HomeShopCardProps = {
  shop: ShopRes
  site?: SiteRes
}

const getDimShopStatusText = (openStatus: OpenStatusEnum, t: TFunction) => {
  switch (openStatus) {
    case OpenStatusEnum.NotOpenTime:
      return t('home:card.common.not_business_hour')
    case OpenStatusEnum.Suspended:
      return t('home:card.shop.preparing_business')
    case OpenStatusEnum.SiteBreakTime:
      return t('home:card.common.maintenance')
    case OpenStatusEnum.ShopBreakTime:
      return isSite(SiteSlugEnum.Secsuwon) ? t('home:card.common.maintenance') : t('home:card.shop.preparing_business')
  }
}

const HomeShopCard = ({ shop, site }: HomeShopCardProps) => {
  const { t } = useTranslation([I18nNamespaceEnum.Home, I18nNamespaceEnum.Common])
  const shopStatus = ShopUtils.getShopStatus(shop, site)
  const { name, id, openAt, lastOrderAt, mainImage } = shop

  return (
    <div className={classNames('flex w-full flex-col gap-8')}>
      <Link
        href={{
          pathname: Routes.Shop.Index,
          query: {
            [RouteKeys.ShopId]: id
          }
        }}>
        <div className="relative h-160 w-full overflow-hidden rounded-sm shadow-md">
          {(shopStatus === OpenStatusEnum.SiteBreakTime || shopStatus !== OpenStatusEnum.Open) && (
            <div className="absolute top-0 z-cardDim flex h-full w-full flex-col items-center justify-center rounded-sm bg-black/70">
              <span className="body2 whitespace-pre-wrap text-center font-medium text-white">
                {getDimShopStatusText(shopStatus, t)}
              </span>
            </div>
          )}
          {shop.hasEvent && (
            <Tag
              className="absolute top-12 left-12 z-cardTag bg-secondary text-white"
              content={t('common:event_on_going')}
            />
          )}
          <Img
            className="h-full w-full"
            src={mainImage.fullSize}
            placeholder="/images/placeholder-main.png"
            alt="Home shop image"
            overlay
          />
        </div>
        <div className="mt-5 flex h-25 items-center justify-between px-4">
          <span className="body1 font-bold text-gray-800">{name}</span>
          <div className="body3 flex items-center gap-2 text-gray-400">
            <Icon name={IconNamesEnum.Clock} className="h-16 w-16" />
            <span>{`${DateUtils.removeSeconds(openAt)}~${DateUtils.removeSeconds(lastOrderAt)}`}</span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default HomeShopCard

export const SkeletonHomeCardList = () => {
  /**
   * Todo: 샵 리스트에 대한 요청이 아얘 안가는 현상이 있습니다. 3단계로 나눠갑니다.
   * 1. 8초 후 하드 리프레시
   * 2. 하드 리프레시 버튼을 보여주기
   * 3. 샵 리스트에 대한 요청을 왜 안보내는지 원인 분석
   */
  useEffect(() => {
    const timerId = setTimeout(() => {
      window.location.reload()
    }, 8000)
    return () => {
      clearTimeout(timerId)
    }
  }, [])

  return (
    <ul className="flex flex-col gap-22">
      <li className={classNames('flex w-full flex-col gap-8')}>
        <SkeletonBase className="h-160 w-full rounded-sm" />
        <div className="mt-5 flex h-25 items-center justify-between px-4">
          <SkeletonBase className="h-25 w-210 rounded-xss" />
          <SkeletonBase className="h-25 w-100 rounded-xss" />
        </div>
      </li>
      <li className={classNames('flex w-full flex-col gap-8')}>
        <SkeletonBase className="h-160 w-full rounded-sm" />
        <div className="mt-5 flex h-25 items-center justify-between px-4">
          <SkeletonBase className="h-25 w-210 rounded-xss" />
          <SkeletonBase className="h-25 w-100 rounded-xss" />
        </div>
      </li>
      <li className={classNames('flex w-full flex-col gap-8')}>
        <SkeletonBase className="h-160 w-full rounded-sm" />
        <div className="mt-5 flex h-25 items-center justify-between px-4">
          <SkeletonBase className="h-25 w-210 rounded-xss" />
          <SkeletonBase className="h-25 w-100 rounded-xss" />
        </div>
      </li>
    </ul>
  )
}
