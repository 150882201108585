import { TFunction } from 'i18next'
import { useTranslation } from 'next-i18next'
import { PolicyTypeEnum } from '@/components/policy/PolicyBottomSheet'
import { I18nNamespaceEnum } from '@/constants/i18n'
import { isHidingFinancialDealTerms } from '@/pages/_app'

const getNeubilityInfo = (t: TFunction) => [
  `${t('home:footer.company')} : ${t('company_info:company')}`,
  `${t('home:footer.ceo')} : ${t('company_info:ceo')}`,
  `${t('home:footer.business_registration_number')} : ${t('company_info:business_registration_number')}`,
  `${t('home:footer.address')} : ${t('company_info:address')}`,
  `${t('home:footer.tel')} : ${t('company_info:tel')}`,
  `${t('home:footer.email')} : ${t('company_info:email')}`
]

type HomeFooterProps = {
  handleOpenPolicy(type: PolicyTypeEnum): void
}

const HomeFooter = ({ handleOpenPolicy }: HomeFooterProps) => {
  const { t } = useTranslation([I18nNamespaceEnum.Home, I18nNamespaceEnum.Common])

  return (
    <footer className="flex w-full flex-col bg-gray-50 px-16 pt-20 pb-40">
      <div className="mb-20 flex overflow-x-scroll scrollbar-hide">
        <button
          onClick={() => handleOpenPolicy(PolicyTypeEnum.ServiceTerms)}
          className="caption whitespace-nowrap border-r-1 border-gray-300 pr-8 font-medium text-gray-700">
          {t('common:terms.service_terms_of_use')}
        </button>
        {!isHidingFinancialDealTerms() && (
          <button
            onClick={() => handleOpenPolicy(PolicyTypeEnum.FinancialDealTerms)}
            className="caption whitespace-nowrap border-r-1 border-gray-300 px-8 font-medium text-gray-700">
            {t('common:terms.eft_terms_and_conditions')}
          </button>
        )}
        <button
          onClick={() => handleOpenPolicy(PolicyTypeEnum.PrivacyPolicy)}
          className="caption whitespace-nowrap pl-8 font-medium text-gray-700">
          {t('common:terms.privacy_policy')}
        </button>
      </div>
      <div className="flex flex-col">
        {getNeubilityInfo(t).map((word) => (
          <span key={word} className="caption font-medium text-gray-500">
            {word}
          </span>
        ))}
        <span className="caption font-medium text-gray-500">{t('home:footer.content')}</span>
      </div>
    </footer>
  )
}

export default HomeFooter
