import { cx } from 'class-variance-authority'
import { ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * twMerge와 clsx를 같이 실행하는 함수. 뒤에 오는 값이 우선순위가 높음
 * @param classValues 클래스 이름들
 */
const cn = (...classValues: ClassValue[]) => {
  return twMerge(cx(classValues))
}

export const ComponentUtils = {
  cn
}
